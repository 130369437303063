import useMediaQuery from "../hooks/useMediaQuery";
import { useEffect, useState } from "react";
import { switchToNetwork, useWallet } from "../ethereum/ethereum";
import config from "../config";
import ImgNextGen from "./ImgNextGen";
import CustomBtn from "./CustomBtn/CustomBtn";
import { BtnType, Size } from "../types";
import classes from "./CustomBtn/CustomBtn.module.scss";
import logo_with_text_lg from "../assets/images/header/logo_with_text_lg.png";
import logo_with_textWebp_lg from "../assets/images/header/logo_with_text_lg.webp";
import logo_with_textJp2_lg from "../assets/images/header/logo_with_text_lg.jp2";
import logo_with_textJxr_lg from "../assets/images/header/logo_with_text_lg.jxr";
import logo_with_text_sm from "../assets/images/header/logo_with_text_sm.png";
import logo_with_textWebp_sm from "../assets/images/header/logo_with_text_sm.webp";
import logo_with_textJp2_sm from "../assets/images/header/logo_with_text_sm.jp2";
import logo_with_textJxr_sm from "../assets/images/header/logo_with_text_sm.jxr";
import logo_with_text_sm_2x from "../assets/images/header/logo_with_text_sm_2x.png";
import logo_with_textWebp_sm_2x from "../assets/images/header/logo_with_text_sm_2x.webp";
import logo_with_textJp2_sm_2x from "../assets/images/header/logo_with_text_sm_2x.jp2";
import logo_with_textJxr_sm_2x from "../assets/images/header/logo_with_text_sm_2x.jxr";
import EthereumInteraction from "../ethereum/EthereumInteraction";
import avax from "../assets/images/header/avax.svg";
import polygon from "../assets/images/header/polygon.svg";
import fantom from "../assets/images/header/fantom.svg";
import CustomModal from "./CustomModal";

interface IHeader {
    refHeader: any;
}

export default function Header({ refHeader }: IHeader,): JSX.Element {
    const bp320px = useMediaQuery(320);
    const bp370px = useMediaQuery(370);
    const bp1538px = useMediaQuery(1538);

    const { wallet, chain, loaded } = useWallet();

    const [collapsed, setCollapsed] = useState(true);

    const avalancheChainIdOptions = {
        development: {
            value: 43113,
            label:
                <>
                    <div className="bg-white flex justify-center items-center w-[45px] h-[45px] rounded-full mr-2">
                        <img src={avax} alt="" width={30} height={30} />
                    </div>
                    Avalanche Fuji
                </>,
            textColor: "text-avax",
            bgColor: "bg-avax",
            bgHoverColor: "hover:bg-avax",
        },
        production: {
            value: 43114,
            label:
                <>
                    <div className="bg-white flex justify-center items-center w-[45px] h-[45px] rounded-full mr-2">
                        <img src={avax} alt="" width={30} height={30} />
                    </div>
                    Avalanche
                </>,
            textColor: "text-avax",
            bgColor: "bg-avax",
            bgHoverColor: "hover:bg-avax",
        },
    };

    const avalancheChain = avalancheChainIdOptions[config.name];

    const [chainToBe, setChainToBe] = useState<number>(() => {
        const kocMoneyChainId = localStorage.getItem("kocMoneyChainId");
        if (kocMoneyChainId) return parseInt(kocMoneyChainId);
        else return avalancheChain?.value;
    });
    const [networkModalOpen, setNetworkModalOpen] = useState(false);

    const networks = [
        avalancheChain,
        {
            value: 137,
            label:
                <>
                    <div className="bg-white flex justify-center items-center w-[45px] h-[45px] rounded-full mr-2">
                        <img src={polygon} alt="" width={30} height={30} />
                    </div>
                    Polygon
                </>,
            textColor: "text-polygon",
            bgColor: "bg-polygon",
            bgHoverColor: "hover:bg-polygon",
        },
        {
            value: 250,
            label:
                <>
                    <div className="bg-white flex justify-center items-center w-[45px] h-[45px] rounded-full mr-2">
                        <img src={fantom} alt="" width={30} height={30} />
                    </div>
                    Fantom Opera
                </>,
            textColor: "text-fantom",
            bgColor: "bg-fantom",
            bgHoverColor: "hover:bg-fantom",
        },
    ]

    useEffect(() => {
        if (bp1538px && !collapsed) setCollapsed(true);
    }, [bp1538px]);

    useEffect(() => {
        const changeNetwork = async () => {
            await switchToNetwork(chainToBe);
            localStorage.setItem("kocMoneyChainId", chainToBe.toString());
        }

        if (chain && chainToBe && chain !== chainToBe) {
            changeNetwork();
            setChainToBe(0);
        }
    }, [chain, chainToBe]);

    const displaySelectedNetwork = () => {
        const currentNetwork = networks.find(n => n.value === chain);
        return (
            <EthereumInteraction
                wallet={wallet}
                chain={chain}
                loaded={loaded}
                connectButton={
                    <CustomBtn size={Size.sm} type={BtnType.outlined}>
                        Connect Metamask
                    </CustomBtn>
                }
                chainSwitchButton={
                    <CustomBtn size={Size.sm} type={BtnType.outlined}>
                        Switch to Avalanche
                    </CustomBtn>
                }
            >
                <div
                    className={`flex items-center cursor-pointer font-medium ${currentNetwork?.textColor}
                    hover:brightness-125 transition duration-300`}
                    onClick={() => setNetworkModalOpen(true)}
                >
                    {currentNetwork?.label}
                </div>
            </EthereumInteraction>
        )
    }

    return (
        <header ref={refHeader} className="fixed top-0 left-0 right-0 z-50 bg-lightblue border-b border-lightsky border-solid">
            <div className={`container mx-auto p-5 flex space-x-5 justify-between items-center`}>
                <a href="https://gladiatorfinance.app" className="text-black text-22 font-medium">
                    <ImgNextGen
                        srcWebp={`${logo_with_textWebp_sm} 210w, ${logo_with_textWebp_lg} 260w, ${logo_with_textWebp_sm_2x} 420w`}
                        srcJp2={`${logo_with_textJp2_sm} 210w, ${logo_with_textJp2_lg} 260w, ${logo_with_textJp2_sm_2x} 420w`}
                        srcJxr={`${logo_with_textJxr_sm} 210w, ${logo_with_textJxr_lg} 260w, ${logo_with_textJxr_sm_2x} 420w`}
                        fallback={`${logo_with_text_sm} 210w, ${logo_with_text_lg} 260w, ${logo_with_text_sm_2x} 420w`}
                        src={logo_with_text_lg}
                        sizes={"(min-width: 370px) 260px, 210px"}
                        width={bp370px ? 260 : bp320px ? 210 : "100%"}
                        height={bp370px ? 44 : bp320px ? 35 : "auto"}
                        alt={""}
                        lazyLoadNotNeeded
                    />
                </a>
                <div className="flex items-center justify-center">
                    <p className="text-gray text-12 sm:text-14">
                        Powered by GladiatorFinance
                    </p>
                </div>

                <div className="flex justify-between space-x-8">
                    {bp1538px
                        ? displaySelectedNetwork()
                        :
                        <div
                            className={
                                `w-[50px] h-fit cursor-pointer 
                                hover:bg-orange hover:bg-opacity-[0.15] opacity-40 hover:opacity-100 
                                px-[10px] py-[7px] rounded-full
                                ${classes.rippleForNavigationBtn}`
                            }
                            onClick={() => setCollapsed(!collapsed)}
                        >
                            <div>
                                {[0, 1, 2].map((elem, i) =>
                                    <div key={i.toString()} className={"w-[100%] h-[3px] bg-orange my-[6px]"} />
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className={`flex justify-center h-0 truncate transition-[height] duration-[350ms] ease-in-out ${!collapsed ? "h-[80px]" : ""}`}>
                {displaySelectedNetwork()}
            </div>
            <CustomModal
                open={networkModalOpen}
                setOpen={(isOpen) => {
                    if (!isOpen) {
                        setNetworkModalOpen(false);
                    }
                }}
                padding="p-0 pt-5"
            >
                <h1 className="text-black text-20 sm:text-24 font-medium text-center mb-5">Switch to</h1>
                <div
                    className={`flex items-center cursor-pointer
                    p-3 ${networks.find(n => n.value === chain)?.bgColor} text-white
                    ${networks.find(n => n.value === chain)?.bgHoverColor} hover:text-white
                    hover:bg-opacity-70 transition duration-300`}
                    onClick={() => {
                        setChainToBe(chainToBe);
                        setNetworkModalOpen(false);
                    }}
                >
                    {networks.find(n => n.value === chain)?.label}
                </div>
                {networks.filter(n => n.value !== chain).map((network, i) => {
                    return (
                        <div
                            key={i.toString()}
                            className={`flex items-center cursor-pointer
                            p-3 ${network.value === chain ? `${network.bgColor} text-white` : network.textColor} 
                            ${network.bgHoverColor} hover:text-white
                            hover:bg-opacity-70 transition duration-300`}
                            onClick={() => {
                                setChainToBe(network?.value);
                                setNetworkModalOpen(false);
                            }}
                        >
                            {network?.label}
                        </div>
                    )
                })}
            </CustomModal>
        </header>
    );
}
