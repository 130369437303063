import { lazy, Suspense, useRef } from "react";
import Loading from "./components/Loading/Loading";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import ToTop from "./components/ToTop";

const KocPage = lazy(() => import("./pages/KocPage/KocPage"));
const NoRoutePage = lazy(() => import("./pages/NoRoutePage"));

function App() {

  const refHeader = useRef();

  const Fallback = (): JSX.Element => {
    return (
      <div className="container mx-auto px-5 flex justify-center bg-lightblue">
        <Loading />
      </div>
    )
  }

  return (
    <Router>
      <Header refHeader={refHeader} />
      <Routes>
        <Route path={"/"} element={<Suspense fallback={<Fallback />}><KocPage refHeader={refHeader} /></Suspense>} />
        <Route path="*" element={<Suspense fallback={<Fallback />}><NoRoutePage /></Suspense>} />
      </Routes>
      <ToTop />
    </Router>
  );
}

export default App;
